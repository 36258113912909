var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Displayed rows"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeRows) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To set the height of ")]), _c('code', [_vm._v("<b-form-textarea>")]), _c('span', [_vm._v(", set the ")]), _c('code', [_vm._v("rows")]), _c('span', [_vm._v(" prop to the desired number of rows. If no value is provided to rows, then it will default to 2.")])]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-rows",
      "placeholder": "Tall textarea",
      "rows": "8"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }