var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Debounce support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDebounce) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To enable debouncing, set the prop ")]), _c('code', [_vm._v("debounce")]), _c('span', [_vm._v(" to any integer greater than zero. The value is specified in milliseconds. Setting ")]), _c('code', [_vm._v("debounce")]), _c('span', [_vm._v(" to ")]), _c('code', [_vm._v("0")]), _c('span', [_vm._v(" will disable debouncing.")])]), _c('b-form-textarea', {
    attrs: {
      "debounce": "500",
      "rows": "3",
      "max-rows": "5"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-card-text', {
    staticClass: "mt-1 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }