var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Control sizing"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Set text height using the ")]), _c('code', [_vm._v("size")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("sm")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("lg")]), _c('span', [_vm._v(" for small or large respectively.")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "2"
    }
  }, [_c('label', {
    staticClass: "text-nowrap",
    attrs: {
      "for": "textarea-small"
    }
  }, [_vm._v("Small:")])]), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "sm": "10"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": "Small textarea"
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "2"
    }
  }, [_c('label', {
    staticClass: "text-nowrap",
    attrs: {
      "for": "textarea-default"
    }
  }, [_vm._v("Default:")])]), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "sm": "10"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Default textarea"
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "2"
    }
  }, [_c('label', {
    staticClass: "text-nowrap",
    attrs: {
      "for": "textarea-large"
    }
  }, [_vm._v("Large:")])]), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "sm": "10"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "textarea-large",
      "size": "lg",
      "placeholder": "Large textarea"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }