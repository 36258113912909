var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Default"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDefault) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" Create multi-line text inputs with support for auto height sizing, minimum and maximum number of rows, and contextual states. ")]), _c('label', {
    attrs: {
      "for": "textarea-default"
    }
  }, [_vm._v("Textarea")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Textarea",
      "rows": "3"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }