var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Floating Label"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFloatingLabel) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v(".form-label-group")]), _c('span', [_vm._v(" to add a Floating Label with Textarea.")])]), _c('div', {
    staticClass: "form-label-group mt-2 mb-0"
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "textarea",
      "rows": "3",
      "placeholder": "Label in Textarea"
    }
  }), _c('label', {
    attrs: {
      "for": "label-textarea"
    }
  }, [_vm._v("Label in Textarea")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }