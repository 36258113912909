var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Disable resize handle"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDisableResize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v(" Some web browsers will allow the user to re-size the height of the textarea. To disable this feature, set the ")]), _c('code', [_vm._v("no-resize")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(".")])]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-no-resize",
      "placeholder": "Fixed height textarea",
      "rows": "3",
      "no-resize": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }