var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Auto height"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAutoHeight) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To set the initial minimum height (in rows), set the")]), _c('code', [_vm._v("rows")]), _c('span', [_vm._v(" prop to the desired number of lines (or leave it at the default of ")]), _c('code', [_vm._v("2")]), _c('span', [_vm._v("), And then set maximum rows that the text area will grow to (before showing a scrollbar) by setting the ")]), _c('code', [_vm._v("max-rows")]), _c('span', [_vm._v("prop to the maximum number of lines of text.")])]), _c('b-card-text', [_c('span', [_vm._v("To make the height ")]), _c('code', [_vm._v("sticky")]), _c('span', [_vm._v(" (i.e. never shrink), set the ")]), _c('code', [_vm._v("no-auto-shrink")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(". The ")]), _c('code', [_vm._v("no-auto-shrink")]), _c('span', [_vm._v(" props has no effect if ")]), _c('code', [_vm._v("max-rows")]), _c('span', [_vm._v(" is not set or is equal to or less than rows.")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": "textarea-auto-height"
    }
  }, [_vm._v("Auto height:")]), _c('b-form-textarea', {
    staticClass: "mb-1 mb-xl-0",
    attrs: {
      "id": "textarea-auto-height",
      "placeholder": "Auto height textarea",
      "rows": "3",
      "max-rows": "8"
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": "textarea-no-auto-shrink"
    }
  }, [_vm._v("No auto-shrink:")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-no-auto-shrink",
      "placeholder": "Auto height (no-shrink) textarea",
      "rows": "3",
      "max-rows": "8",
      "no-auto-shrink": ""
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }