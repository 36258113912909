var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('form-text-area-default'), _c('form-text-area-rows'), _c('form-text-area-floating-label'), _c('form-text-area-states'), _c('form-text-area-size'), _c('form-text-area-disable-resize'), _c('form-text-area-auto-height'), _c('formatter-text-area'), _c('form-text-area-readonly'), _c('form-text-area-debounce'), _c('form-text-area-counter')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }