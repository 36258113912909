var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Readonly plain text"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeReadonly) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("If you want to have ")]), _c('code', [_vm._v("<b-form-textarea readonly>")]), _c('span', [_vm._v(" elements in your form styled as plain text, set the ")]), _c('code', [_vm._v("plaintext")]), _c('span', [_vm._v(" prop (no need to set ")]), _c('code', [_vm._v("readonly")]), _c('span', [_vm._v(" as it will be set automatically) to remove the default form field styling and preserve the correct text size, margin, padding and height. ")])]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-plaintext",
      "readonly": "",
      "value": _vm.text
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }