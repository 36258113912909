var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Counter"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCounter) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" There are times when we need the user to only enter a certain number of characters for it, we have the property counter, the value is a number and determines the maximum. ")]), _c('b-form-textarea', {
    staticClass: "char-textarea",
    class: _vm.value.length >= _vm.maxChar ? 'text-danger' : '',
    attrs: {
      "placeholder": "Counter",
      "rows": "3",
      "state": _vm.value.length <= _vm.maxChar
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('small', {
    staticClass: "textarea-counter-value float-right",
    class: _vm.value.length >= _vm.maxChar ? 'bg-danger' : ''
  }, [_c('span', {
    staticClass: "char-count"
  }, [_vm._v(_vm._s(_vm.value.length))]), _vm._v(" / " + _vm._s(_vm.maxChar) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }